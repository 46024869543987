exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-templates-project-js-content-file-path-src-content-projects-r-agent-based-generative-facade-design-index-mdx": () => import("./../../../src/components/templates/project.js?__contentFilePath=/opt/build/repo/src/content/projects/R_agent-based-generative-facade-design/index.mdx" /* webpackChunkName: "component---src-components-templates-project-js-content-file-path-src-content-projects-r-agent-based-generative-facade-design-index-mdx" */),
  "component---src-components-templates-project-js-content-file-path-src-content-projects-r-agent-based-thickness-index-mdx": () => import("./../../../src/components/templates/project.js?__contentFilePath=/opt/build/repo/src/content/projects/R_agent-based-thickness/index.mdx" /* webpackChunkName: "component---src-components-templates-project-js-content-file-path-src-content-projects-r-agent-based-thickness-index-mdx" */),
  "component---src-components-templates-project-js-content-file-path-src-content-projects-r-agent-teams-index-mdx": () => import("./../../../src/components/templates/project.js?__contentFilePath=/opt/build/repo/src/content/projects/R_agent-teams/index.mdx" /* webpackChunkName: "component---src-components-templates-project-js-content-file-path-src-content-projects-r-agent-teams-index-mdx" */),
  "component---src-components-templates-project-js-content-file-path-src-content-projects-r-behavioral-form-finding-index-mdx": () => import("./../../../src/components/templates/project.js?__contentFilePath=/opt/build/repo/src/content/projects/R_BehavioralFormFinding/index.mdx" /* webpackChunkName: "component---src-components-templates-project-js-content-file-path-src-content-projects-r-behavioral-form-finding-index-mdx" */),
  "component---src-components-templates-project-js-content-file-path-src-content-projects-r-brickolage-index-mdx": () => import("./../../../src/components/templates/project.js?__contentFilePath=/opt/build/repo/src/content/projects/R_Brickolage/index.mdx" /* webpackChunkName: "component---src-components-templates-project-js-content-file-path-src-content-projects-r-brickolage-index-mdx" */),
  "component---src-components-templates-project-js-content-file-path-src-content-projects-r-crystal-decoded-index-mdx": () => import("./../../../src/components/templates/project.js?__contentFilePath=/opt/build/repo/src/content/projects/R_crystal-decoded/index.mdx" /* webpackChunkName: "component---src-components-templates-project-js-content-file-path-src-content-projects-r-crystal-decoded-index-mdx" */),
  "component---src-components-templates-project-js-content-file-path-src-content-projects-r-environmental-aware-form-finding-index-mdx": () => import("./../../../src/components/templates/project.js?__contentFilePath=/opt/build/repo/src/content/projects/R_environmental-aware-form-finding/index.mdx" /* webpackChunkName: "component---src-components-templates-project-js-content-file-path-src-content-projects-r-environmental-aware-form-finding-index-mdx" */),
  "component---src-components-templates-project-js-content-file-path-src-content-projects-r-euler-pavilion-index-mdx": () => import("./../../../src/components/templates/project.js?__contentFilePath=/opt/build/repo/src/content/projects/R_euler-pavilion/index.mdx" /* webpackChunkName: "component---src-components-templates-project-js-content-file-path-src-content-projects-r-euler-pavilion-index-mdx" */),
  "component---src-components-templates-project-js-content-file-path-src-content-projects-r-hpr-bot-index-mdx": () => import("./../../../src/components/templates/project.js?__contentFilePath=/opt/build/repo/src/content/projects/R_hpr-bot/index.mdx" /* webpackChunkName: "component---src-components-templates-project-js-content-file-path-src-content-projects-r-hpr-bot-index-mdx" */),
  "component---src-components-templates-project-js-content-file-path-src-content-projects-r-meta-predictive-matter-index-mdx": () => import("./../../../src/components/templates/project.js?__contentFilePath=/opt/build/repo/src/content/projects/R_meta-predictive-matter/index.mdx" /* webpackChunkName: "component---src-components-templates-project-js-content-file-path-src-content-projects-r-meta-predictive-matter-index-mdx" */),
  "component---src-components-templates-project-js-content-file-path-src-content-projects-r-new-view-pavilion-index-mdx": () => import("./../../../src/components/templates/project.js?__contentFilePath=/opt/build/repo/src/content/projects/R_new-view-pavilion/index.mdx" /* webpackChunkName: "component---src-components-templates-project-js-content-file-path-src-content-projects-r-new-view-pavilion-index-mdx" */),
  "component---src-components-templates-project-js-content-file-path-src-content-projects-r-phototropia-index-mdx": () => import("./../../../src/components/templates/project.js?__contentFilePath=/opt/build/repo/src/content/projects/R_phototropia/index.mdx" /* webpackChunkName: "component---src-components-templates-project-js-content-file-path-src-content-projects-r-phototropia-index-mdx" */),
  "component---src-components-templates-project-js-content-file-path-src-content-projects-r-spatial-aggregations-1-index-mdx": () => import("./../../../src/components/templates/project.js?__contentFilePath=/opt/build/repo/src/content/projects/R_SpatialAggregations-1/index.mdx" /* webpackChunkName: "component---src-components-templates-project-js-content-file-path-src-content-projects-r-spatial-aggregations-1-index-mdx" */),
  "component---src-components-templates-project-js-content-file-path-src-content-projects-r-spatial-aggregations-2-index-mdx": () => import("./../../../src/components/templates/project.js?__contentFilePath=/opt/build/repo/src/content/projects/R_SpatialAggregations-2/index.mdx" /* webpackChunkName: "component---src-components-templates-project-js-content-file-path-src-content-projects-r-spatial-aggregations-2-index-mdx" */),
  "component---src-components-templates-project-js-content-file-path-src-content-projects-r-tetra-forming-index-mdx": () => import("./../../../src/components/templates/project.js?__contentFilePath=/opt/build/repo/src/content/projects/R_tetraForming/index.mdx" /* webpackChunkName: "component---src-components-templates-project-js-content-file-path-src-content-projects-r-tetra-forming-index-mdx" */),
  "component---src-components-templates-project-js-content-file-path-src-content-projects-t-arch-305-b-building-physics-index-mdx": () => import("./../../../src/components/templates/project.js?__contentFilePath=/opt/build/repo/src/content/projects/T_ARCH305b-building-physics/index.mdx" /* webpackChunkName: "component---src-components-templates-project-js-content-file-path-src-content-projects-t-arch-305-b-building-physics-index-mdx" */),
  "component---src-components-templates-project-js-content-file-path-src-content-projects-t-arch-490-form-finding-index-mdx": () => import("./../../../src/components/templates/project.js?__contentFilePath=/opt/build/repo/src/content/projects/T_ARCH490-form-finding/index.mdx" /* webpackChunkName: "component---src-components-templates-project-js-content-file-path-src-content-projects-t-arch-490-form-finding-index-mdx" */),
  "component---src-components-templates-project-js-content-file-path-src-content-projects-t-arch-590-parametric-facade-desgn-index-mdx": () => import("./../../../src/components/templates/project.js?__contentFilePath=/opt/build/repo/src/content/projects/T_ARCH590-parametric-facade-desgn/index.mdx" /* webpackChunkName: "component---src-components-templates-project-js-content-file-path-src-content-projects-t-arch-590-parametric-facade-desgn-index-mdx" */),
  "component---src-components-templates-project-js-content-file-path-src-content-projects-t-arch-599-reci-inceon-index-mdx": () => import("./../../../src/components/templates/project.js?__contentFilePath=/opt/build/repo/src/content/projects/T_ARCH599-reci-inceon/index.mdx" /* webpackChunkName: "component---src-components-templates-project-js-content-file-path-src-content-projects-t-arch-599-reci-inceon-index-mdx" */),
  "component---src-components-templates-project-js-content-file-path-src-content-projects-t-ce-107-bim-index-mdx": () => import("./../../../src/components/templates/project.js?__contentFilePath=/opt/build/repo/src/content/projects/T_CE107-BIM/index.mdx" /* webpackChunkName: "component---src-components-templates-project-js-content-file-path-src-content-projects-t-ce-107-bim-index-mdx" */),
  "component---src-components-templates-project-js-content-file-path-src-content-projects-t-ce-469-sustainable-design-construction-index-mdx": () => import("./../../../src/components/templates/project.js?__contentFilePath=/opt/build/repo/src/content/projects/T_CE469-Sustainable-Design-Construction/index.mdx" /* webpackChunkName: "component---src-components-templates-project-js-content-file-path-src-content-projects-t-ce-469-sustainable-design-construction-index-mdx" */),
  "component---src-components-templates-project-js-content-file-path-src-content-projects-t-ce-566-project-controls-index-mdx": () => import("./../../../src/components/templates/project.js?__contentFilePath=/opt/build/repo/src/content/projects/T_CE566-project-controls/index.mdx" /* webpackChunkName: "component---src-components-templates-project-js-content-file-path-src-content-projects-t-ce-566-project-controls-index-mdx" */),
  "component---src-components-templates-project-js-content-file-path-src-content-projects-t-folding-facade-w-index-mdx": () => import("./../../../src/components/templates/project.js?__contentFilePath=/opt/build/repo/src/content/projects/T_folding-facade-w/index.mdx" /* webpackChunkName: "component---src-components-templates-project-js-content-file-path-src-content-projects-t-folding-facade-w-index-mdx" */),
  "component---src-components-templates-project-js-content-file-path-src-content-projects-t-revisting-candela-w-index-mdx": () => import("./../../../src/components/templates/project.js?__contentFilePath=/opt/build/repo/src/content/projects/T_revisting-candela-w/index.mdx" /* webpackChunkName: "component---src-components-templates-project-js-content-file-path-src-content-projects-t-revisting-candela-w-index-mdx" */),
  "component---src-components-templates-project-js-content-file-path-src-content-projects-t-sensing-kites-w-index-mdx": () => import("./../../../src/components/templates/project.js?__contentFilePath=/opt/build/repo/src/content/projects/T_sensing-kites-w/index.mdx" /* webpackChunkName: "component---src-components-templates-project-js-content-file-path-src-content-projects-t-sensing-kites-w-index-mdx" */),
  "component---src-components-templates-project-js-content-file-path-src-content-projects-t-tamu-w-index-mdx": () => import("./../../../src/components/templates/project.js?__contentFilePath=/opt/build/repo/src/content/projects/T_tamu-w/index.mdx" /* webpackChunkName: "component---src-components-templates-project-js-content-file-path-src-content-projects-t-tamu-w-index-mdx" */),
  "component---src-components-templates-project-js-content-file-path-src-content-projects-w-bh-index-mdx": () => import("./../../../src/components/templates/project.js?__contentFilePath=/opt/build/repo/src/content/projects/W_BH/index.mdx" /* webpackChunkName: "component---src-components-templates-project-js-content-file-path-src-content-projects-w-bh-index-mdx" */),
  "component---src-components-templates-project-js-content-file-path-src-content-projects-w-drawing-index-mdx": () => import("./../../../src/components/templates/project.js?__contentFilePath=/opt/build/repo/src/content/projects/W_Drawing/index.mdx" /* webpackChunkName: "component---src-components-templates-project-js-content-file-path-src-content-projects-w-drawing-index-mdx" */),
  "component---src-components-templates-project-js-content-file-path-src-content-projects-w-ibigroup-index-mdx": () => import("./../../../src/components/templates/project.js?__contentFilePath=/opt/build/repo/src/content/projects/W_Ibigroup/index.mdx" /* webpackChunkName: "component---src-components-templates-project-js-content-file-path-src-content-projects-w-ibigroup-index-mdx" */),
  "component---src-components-templates-project-js-content-file-path-src-content-projects-w-studio-pz-lv-facade-index-mdx": () => import("./../../../src/components/templates/project.js?__contentFilePath=/opt/build/repo/src/content/projects/W_Studio-pz-lv-facade/index.mdx" /* webpackChunkName: "component---src-components-templates-project-js-content-file-path-src-content-projects-w-studio-pz-lv-facade-index-mdx" */),
  "component---src-components-templates-project-js-content-file-path-src-content-projects-w-studio-pz-namoc-index-mdx": () => import("./../../../src/components/templates/project.js?__contentFilePath=/opt/build/repo/src/content/projects/W_Studio-pz-namoc/index.mdx" /* webpackChunkName: "component---src-components-templates-project-js-content-file-path-src-content-projects-w-studio-pz-namoc-index-mdx" */),
  "component---src-components-templates-project-js-content-file-path-src-content-projects-w-topo-billfold-index-mdx": () => import("./../../../src/components/templates/project.js?__contentFilePath=/opt/build/repo/src/content/projects/W_topo-billfold/index.mdx" /* webpackChunkName: "component---src-components-templates-project-js-content-file-path-src-content-projects-w-topo-billfold-index-mdx" */),
  "component---src-components-templates-project-js-content-file-path-src-content-projects-w-topo-enlite-index-mdx": () => import("./../../../src/components/templates/project.js?__contentFilePath=/opt/build/repo/src/content/projects/W_topo-enlite/index.mdx" /* webpackChunkName: "component---src-components-templates-project-js-content-file-path-src-content-projects-w-topo-enlite-index-mdx" */),
  "component---src-components-templates-project-js-content-file-path-src-content-projects-w-topo-monostyle-residence-index-mdx": () => import("./../../../src/components/templates/project.js?__contentFilePath=/opt/build/repo/src/content/projects/W_topo-monostyle-residence/index.mdx" /* webpackChunkName: "component---src-components-templates-project-js-content-file-path-src-content-projects-w-topo-monostyle-residence-index-mdx" */),
  "component---src-components-templates-project-js-content-file-path-src-content-projects-w-topo-one-warrior-shell-index-mdx": () => import("./../../../src/components/templates/project.js?__contentFilePath=/opt/build/repo/src/content/projects/W_topo-one-warrior-shell/index.mdx" /* webpackChunkName: "component---src-components-templates-project-js-content-file-path-src-content-projects-w-topo-one-warrior-shell-index-mdx" */),
  "component---src-components-templates-project-js-content-file-path-src-content-projects-w-v-jichan-index-mdx": () => import("./../../../src/components/templates/project.js?__contentFilePath=/opt/build/repo/src/content/projects/W_V_jichan/index.mdx" /* webpackChunkName: "component---src-components-templates-project-js-content-file-path-src-content-projects-w-v-jichan-index-mdx" */),
  "component---src-components-templates-project-js-content-file-path-src-content-projects-w-vagio-bifur-jewel-index-mdx": () => import("./../../../src/components/templates/project.js?__contentFilePath=/opt/build/repo/src/content/projects/W_Vagio-bifur-jewel/index.mdx" /* webpackChunkName: "component---src-components-templates-project-js-content-file-path-src-content-projects-w-vagio-bifur-jewel-index-mdx" */),
  "component---src-components-templates-project-js-content-file-path-src-content-projects-w-vagio-crease-jewel-index-mdx": () => import("./../../../src/components/templates/project.js?__contentFilePath=/opt/build/repo/src/content/projects/W_Vagio-crease-jewel/index.mdx" /* webpackChunkName: "component---src-components-templates-project-js-content-file-path-src-content-projects-w-vagio-crease-jewel-index-mdx" */),
  "component---src-components-templates-project-js-content-file-path-src-content-projects-w-vagio-index-mdx": () => import("./../../../src/components/templates/project.js?__contentFilePath=/opt/build/repo/src/content/projects/W_vagio/index.mdx" /* webpackChunkName: "component---src-components-templates-project-js-content-file-path-src-content-projects-w-vagio-index-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-practice-js": () => import("./../../../src/pages/practice.js" /* webpackChunkName: "component---src-pages-practice-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-sandbox-codes-scripts-js": () => import("./../../../src/pages/sandbox/codes-scripts.js" /* webpackChunkName: "component---src-pages-sandbox-codes-scripts-js" */),
  "component---src-pages-sandbox-index-js": () => import("./../../../src/pages/sandbox/index.js" /* webpackChunkName: "component---src-pages-sandbox-index-js" */),
  "component---src-pages-teaching-js": () => import("./../../../src/pages/teaching.js" /* webpackChunkName: "component---src-pages-teaching-js" */)
}

